<template>
   <v-container fluid pa-0 pt-4 style="height: 100%; max-height: calc(100vh - 60px); overflow-x: hidden;" grid-list-xl>
     <v-layout column nowrap justify-start align-center fill-height class="pr-4">
       <v-flex xs3 style="width: 100%"
               v-for="( slot, index ) in settingsSlots"
               :key="index"
               :style="{ 'display': getDisplay(slot) }"
       >
         <v-layout row norwap justify-space-between align-center>
           <!-- Icon -->
           <v-flex xs2 ml-4 v-if="$vuetify.breakpoint.mdAndUp">
             <v-layout column justify-center>
               <slot :name="`icon${index}`">
                 <v-icon size="60" :color="config['icons'][`icon${index}`].color">{{ config['icons'][`icon${index}`].icon }}</v-icon>
               </slot>
             </v-layout>
           </v-flex>
           <!-- Details -->
           <v-flex xs12 md10>
             <slot :name="`slot${index}`"/>
           </v-flex>
         </v-layout>
       </v-flex>
     </v-layout>
   </v-container>
</template>

<script>
export default {
  name: 'settingsTemplate',
  props: {
    config: {
      type: Object,
      required: true
    },
    search: {
      type: String,
      required: true
    }
  },
  computed: {
    settingsSlots () {
      const slotters = []
      for (const slot in this.$slots) {
        if (slot.includes('slot')) {
          slotters.push(slot)
        }
      }
      return slotters
    }
  },
  methods: {
    getDisplay (slot) {
      const s = this.config[slot].toLowerCase()
      const search = this.search.toLowerCase()
      return s.indexOf(search) > -1 ? 'inline' : 'none'
    }
  }
}
</script>
